@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

@layer utilities {
  .flex-row-centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .appearance-default {
    -webkit-appearance: none;
  }
}
